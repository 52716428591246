<template>
    <v-menu left bottom>
        <template v-slot:activator="{on}">
            <v-btn v-on="on" text small class="text-capitalize">
                <template v-if="$root.$i18n.locale == 'en'">
                    <!-- <span>{{$root.$i18n.locale}}</span> -->
                    <span class="text--disabled">Lang:</span>
                    <v-avatar size="20px" class="ml-2">
                        <v-img src="@/assets/images/flags/1x1/gb.svg" alt />
                    </v-avatar>                                
                </template>                
                <template v-else>
                    <span class="text--disabled">Lang:</span>
                    <v-avatar size="20px" class="ml-2">
                        <v-img src="@/assets/images/flags/1x1/gr.svg" alt />
                    </v-avatar>                                
                </template>                
            </v-btn>
        </template>
        <v-list>
            <v-list-item  @click="setGlobalLocale('gr')">
                <v-list-item-title>
                    <v-avatar size="14px" class="mr-2">
                        <v-img src="@/assets/images/flags/1x1/gr.svg" alt />
                    </v-avatar>      
                    <span class="text--disabled-" style="line-height: 25px">Ελληνικά</span>                                                
                </v-list-item-title>                                            
            </v-list-item>
            <v-list-item @click="setGlobalLocale('en')">
                <v-list-item-title>
                    <v-avatar size="14px" class="mr-2">
                        <v-img src="@/assets/images/flags/1x1/gb.svg" alt />
                    </v-avatar>      
                    <span class="text--disabled-" style="line-height: 25px">English</span>                                                
                </v-list-item-title>                                            
            </v-list-item>
            <!-- <v-list-item>
                <v-list-item-title>Choice 2</v-list-item-title>
            </v-list-item> -->
        </v-list>
    </v-menu>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    
    export default {
        name: 'LanguageSelector',
        props: {

        },
        data() {
            return {
                
            }
        },
        methods: {
            ...mapActions([                
                "setLanguage"
            ]),
            setGlobalLocale(targetLocale) {
                //alert(targetLocale);
                this.$root.$i18n.locale = targetLocale;     
                this.setLanguage(targetLocale);
                //alert(this.$root.$i18n.locale);
                //alert($i18n.global.locale);
            }
        }
    }
</script>